<template>
  <div class="">
    <h1 class="mt-5 d-flex align-center">
      Advisor Management <v-spacer></v-spacer>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        elevation="0"
        :to="{ name: 'AdminAdvisorAdd', params: { id: null } }"
      >
        Add Advisor
      </v-btn>
    </h1>
    <!-- <p class="red--text darken-4">PLANGAP PERSONNEL ONLY!</p> -->
    <!-- <p>Notes:</p>
    <ul>
      <li>
        Not yet possible to update company logo or advisor avatar after it is
        added. Will need to manually removed from storage and database.
      </li>
    </ul> -->
    <v-row class="my-8">
      <v-col class="col-12">
        <v-data-table
          :loading="tableLoading"
          loading-text="Loading Advisors... Please wait"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :footer-props="{
            itemsPerPageOptions: itemsPerPage,
          }"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-toolbar flat class="mb-4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
                @input="fetchData"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-data-footer
                :pagination="pagination"
                :options.sync="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="itemsPerPage"
              />
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <small>{{ item.lastName }}</small>
              </td>
              <td>
                <small>{{ item.firstName }}</small>
              </td>
              <td>
                <small>{{ item.email }}</small
                ><br />
                <small class="grey--text">{{ item.id }}</small>
              </td>
              <td>
                <small>
                  {{ formatDate(item.createdAt) }}
                </small>
              </td>
              <td>
                {{ item.companyName ? item.companyName : "--" }}
              </td>
              <td class="text-center">
                <a
                  v-if="item.prospects && item.prospects.items.length > 0"
                  href="#"
                  @click.prevent="handleOpenProspects(item)"
                  class="text-decoration-none"
                  >View</a
                >
                <span v-else>--</span>
              </td>
              <td class="text-center">
                <a
                  v-if="item.vanityNames && item.vanityNames.items.length > 0"
                  href="#"
                  @click.prevent="handleOpenVanityNames(item)"
                  class="text-decoration-none"
                  >View</a
                >
                <span v-else>--</span>
              </td>
              <td>
                <v-tooltip top nudge-bottom="5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="warning"
                      small
                      class="mr-2"
                      :to="{
                        name: 'AdminAdvisorEdit',
                        params: { id: item.id },
                      }"
                    >
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip top nudge-bottom="5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="error"
                      small
                      @click="deleteAdvisor(item)"
                    >
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay :value="showOverlay" color="#091437" z-index="10000">
      <v-progress-circular indeterminate size="70"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="showSnackBar"
      :timeout="snackBarTimeout"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackBar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-if="advisor"
      v-model="dialogDelete"
      persistent
      max-width="500px"
    >
      <v-card v-if="deleteAdvisorLoading">
        <v-card-text>
          <div
            class="
              h-100
              d-flex
              flex-column
              align-center
              justify-center
              pa-6
              pb-0
            "
          >
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
            <h3 class="mt-4">Deleting advisor...</h3>
          </div>
        </v-card-text>
      </v-card>

      <v-card v-else>
        <v-card-title class="text-h6"
          >Are you sure you want to
          <span class="red--text">&nbsp; DELETE &nbsp; </span>
          advisor:
          <small>{{ advisor.firstName }} {{ advisor.lastName }}</small
          >?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="handleDeleteAdvisor"
            >DELETE</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="advisor" v-model="dialogProspects" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Prospects</v-card-title>
        <v-card-text>
          <v-container v-if="advisor.prospects">
            <p v-for="p in advisor.prospects.items" :key="p.id">
              {{ p.id }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogProspects = false"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="advisor" v-model="dialogVanityNames" max-width="500px">
      <v-card>
        <v-card-title class="text-h6 justify-center"
          >Advisor {{ advisor.firstName + " " + advisor.lastName }}'s Vanity
          Names</v-card-title
        >
        <v-card-text>
          <v-container v-if="advisor.vanityNames">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Vanity Name</th>
                    <th class="text-left">Copy Advisor Link</th>
                    <th class="text-left">Open Advisor Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="v in advisor.vanityNames.items" :key="v.id">
                    <td>{{ v.vanityName }}</td>
                    <td class="text-center">
                      <v-btn
                        @click="handleCopyLink(v.vanityName)"
                        icon
                        class="text-decoration-none"
                      >
                        <v-icon>mdi-content-copy</v-icon></v-btn
                      >
                    </td>
                    <td class="text-center">
                      <v-btn
                        :href="getLink('score', v.vanityName)"
                        target="_blank"
                        icon
                        class="text-decoration-none"
                        ><v-icon>mdi-open-in-new</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogVanityNames = false"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from "uuid";
import { API, graphqlOperation, Storage } from "aws-amplify";

import {
  // createAdvisor,
  deleteAdvisor,
} from "@/graphql/mutations";
import { onCreateAdvisor, onDeleteAdvisor } from "@/graphql/subscriptions";
import linkBuilderMixin from "@/mixins/linkBuilderMixin";
import parseCriteriaData from "@/mixins/parseAdvisorCriteria";
import tableSearchPaginateMixin from "@/mixins/tableSearchPaginateMixin";

import { deleteCognitoUser } from "@/services/cognitoAdminQueries";

const initialProspectCriteria = [
  {
    birthYearRange: [1942, 1996],
    retirementSavings: "$0",
  },
  {
    birthYearRange: [1942, 1996],
    retirementSavings: "$0",
  },
];

const initialAdvisor = function () {
  return {
    companyName: null,
    companyUrl: null,
    companyLogo: null,
    companyAddress: null,
    address: null,
    firstName: null,
    lastName: null,
    credentials: null,
    email: null,
    phone: null,
    avatar: null,
    prospectCriteria: initialProspectCriteria,
    showAdvisorCard: true,
    vanityNames: { items: [] },
  };
};

export default {
  mixins: [parseCriteriaData, linkBuilderMixin, tableSearchPaginateMixin],
  data() {
    return {
      queryContext: "advisors",
      advisor: null,
      date: null,
      showOverlay: false,
      mode: "new", // new or edit
      dialogProspects: false,
      dialogVanityNames: false,
      dialogDelete: false,
      deleteAdvisorLoading: false,
      editedIndex: -1,
      headers: [
        {
          text: "Last Name",
          align: "start",
          value: "lastName",
        },
        {
          text: "First Name",
          align: "start",
          value: "firstName",
        },
        { text: "Email", value: "email" },
        {
          text: "Created",
          align: "start",
          value: "createdAt",
        },
        { text: "Company", value: "companyName" },
        {
          text: "Prospects",
          value: "prospects",
          align: "center",
          sortable: false,
        },
        {
          text: "Vanity URLs",
          value: "vanityUrls",
          align: "center",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      linkBase: process.env.VUE_APP_LINK_BASE, //"https://riskscore.us",
      link: null,
      linkCopied: false,
      advisorLink: null,
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "info",
      snackBarTimeout: 3000,
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    //Subscribe to changes
    API.graphql(graphqlOperation(onCreateAdvisor)).subscribe((sourceData) => {
      const newAdvisor = sourceData.value.data.onCreateAdvisor;
      if (newAdvisor) {
        // skip our own mutations and duplicates
        if (this.items.some((r) => r.id == newAdvisor.id)) return;
        this.items = [newAdvisor, ...this.items];
      }
    });
    // API.graphql(graphqlOperation(onUpdateAdvisor)).subscribe((sourceData) => {
    //   const updatedAdvisor = sourceData.value.data.onUpdateAdvisor;
    //   if (updatedAdvisor) {
    //     this.items = this.items.filter(
    //       (r) => r.id != updatedAdvisor.id
    //     );
    //   }
    // });
    API.graphql(graphqlOperation(onDeleteAdvisor)).subscribe((sourceData) => {
      const deletedAdvisor = sourceData.value.data.onDeleteAdvisor;
      if (deletedAdvisor) {
        this.items = this.items.filter((r) => r.id != deletedAdvisor.id);
      }
    });
  },

  methods: {
    generateUsername() {
      //
    },
    async handleCopyLink(vanityName) {
      try {
        const path = process.env.VUE_APP_SCORE_LINK_PARAM;
        const vanityLink = await this.copyLink(path, vanityName);

        if (vanityLink) {
          this.linkCopied = true;

          this.handleSnackbar({
            isShowing: true,
            color: "info",
            text: "Advisor's Vanity Link Copied!",
          });
        }
        setTimeout(() => {
          this.linkCopied = false;
        }, 3000);
      } catch ($e) {
        this.linkCopied = false;
      } finally {
        this.dialogVanityNames = false;
      }
    },
    // generateLink() {
    //   // if in dev, skip bitly
    //   if (process.env.NODE_ENV === "development") {
    //     this.advisorLink = `${this.linkBase}?an=${encodeURIComponent(
    //       this.advisor.name
    //     )}&ae=${this.advisor.email}&ce=${this.advisor.email}`;

    //     this.createAdvisor(this.advisor.email, this.advisorLink);

    //     return;
    //   }

    //   const url = process.env.VUE_APP_BITLY_SHORTEN_URL;
    //   const longUrl = `${this.linkBase}?an=${encodeURIComponent(
    //     this.advisor.name
    //   )}&ae=${this.advisor.email}&ce=${this.advisor.email}`;
    //   console.log("long url: ", longUrl);
    //   fetch(url, {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${process.env.VUE_APP_BITLY_TOKEN}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       long_url: longUrl,
    //       domain: "bit.ly",
    //       group_guid: process.env.VUE_APP_BITLY_GROUP_GUID,
    //     }),
    //   })
    //     .then((response) => {
    //       response.json().then((data) => {
    //         this.advisorLink = data.link;
    //       });

    //       this.createAdvisor(this.advisor.email, this.advisorLink);
    //     })
    //     .catch((error) => {
    //       console.log("bitly error: ", error);
    //     });
    // },

    async handleDeleteAdvisor() {
      const advisor = this.advisor;
      this.deleteAdvisorLoading = true;

      if (advisor) {
        try {
          const { id } = advisor;

          const result = await deleteCognitoUser(advisor);
          console.log(
            "delete cognito user result",
            result.response.data?.message
          );

          if (
            result &&
            result.response &&
            result.response.status !== 200 &&
            result.response.data?.message !== "User does not exist."
          ) {
            throw new Error(result.response.data.message);
          } else if (result && result.message) {
            console.log(
              "in IF for delete cognito user result.message",
              result.message
            );

            await API.graphql(
              graphqlOperation(deleteAdvisor, { input: { id: id } })
            );

            try {
              if (advisor.companyLogo && advisor.companyLogo.key) {
                await Storage.remove(advisor.companyLogo.key);
              }

              if (advisor.avatar && advisor.avatar.key) {
                await Storage.remove(advisor.avatar.key);
              }
            } catch (error) {
              console.log(
                "Error deleting image from S3 when deleting advisor",
                error
              );
            }

            console.log("Advisor deleted!");
            this.items = this.items.filter((r) => r.id != advisor.id);

            this.handleSnackbar({
              isShowing: true,
              color: "success",
              text: `Advisor ${advisor.firstName} ${advisor.lastName} has been DELETED!`,
            });

            this.closeDelete();
          } else {
            throw new Error(result.response.data.message);
          }
        } catch (error) {
          console.log("Error deleting advisor...", error);
          this.advisor = null;
          this.handleSnackbar({
            isShowing: true,
            color: "error",
            text: `Error Deleting advisor: ${error}`,
            timeout: 10000,
          });
        } finally {
          this.deleteAdvisorLoading = false;
        }
      }
    },

    handleOpenProspects(item) {
      if (item) {
        this.editedIndex = this.items.indexOf(item);
        this.advisor = Object.assign({}, item);
      }

      this.dialogProspects = true;
    },
    handleOpenVanityNames(item) {
      if (item) {
        this.editedIndex = this.items.indexOf(item);
        this.advisor = Object.assign({}, item);
      }

      this.dialogVanityNames = true;
    },
    deleteAdvisor(item) {
      this.editedIndex = this.items.indexOf(item);
      this.advisor = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.advisor = Object.assign({}, new initialAdvisor());
        this.editedIndex = -1;
      });
    },
    handleSnackbar({ isShowing, color, text, timeout }) {
      this.showSnackBar = isShowing;
      this.snackBarColor = color;
      this.snackBarText = text;
      this.snackBarTimeout = timeout;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("default", {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(date);
    },
  },
};
</script>

<style lang="scss">
.v-alert {
  a {
    word-break: break-all;
  }
}

pre {
  white-space: pre-line;
}
</style>
